export type FocusStrategy =
  | 'center'
  | 'top'
  | 'right'
  | 'left'
  | 'bottom'
  | 'top_right'
  | 'top_left'
  | 'bottom_right'
  | 'bottom_left'
  | 'face'
  | 'faces';

export type FitStrategy = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

// https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/specify-focus-area
export interface ImageOptions {
  w?: number;
  h?: number;
  fm?: 'jpg' | 'png' | 'webp' | 'gif' | 'avif';
  fit?: FitStrategy;
  f?: FocusStrategy;
  q?: number;
}

export function imageUrl(url: string | undefined, options: ImageOptions) {
  if (!url) {
    return '';
  }

  // This only works if it's a contentful asset, otherwise return the url
  if (!url.includes('ctfassets')) {
    return url;
  }

  const urlObj = new URL(url);

  Object.entries(options).forEach(([key, val]) => {
    if (val) {
      urlObj.searchParams.append(key, val);
    }
  });

  return urlObj.toString();
}
