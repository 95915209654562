<script lang="ts">
  import { imageUrl, type FocusStrategy, type FitStrategy } from './image';

  export let src: string | undefined;

  export let focusStrategy: FocusStrategy | undefined = undefined;
  export let fitStrategy: FitStrategy | undefined = undefined;

  export let width: number | undefined = undefined;
  export let height: number | undefined = undefined;

  export let alt: string | undefined;

  export let jpgQuality: number = 80;
  export let avifQuality: number = 60;
  export let webpQuality: number = 80;

  export let entityId: string | undefined = undefined;
  export let fieldId: string | undefined = undefined;

  const isContentful = src ? src.includes('ctfassets') : false;

  // w: (width ?? 0) * 1.5, when this is 0 it should get filtered out in imageUrl
  // so we can "safely" multiply when that's a thing
</script>

{#if src}
  {#if src.endsWith('.svg')}
    <img
      {src}
      {alt}
      {width}
      {height}
      class={$$props.class}
      data-contentful-entry-id={entityId}
      data-contentful-field-id={fieldId}
    />
  {:else if isContentful}
    <picture class={$$props.pictureClass}>
      <source
        type="image/avif"
        srcset={`${imageUrl(src, {
          w: width,
          h: height,
          fm: 'avif',
          q: avifQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })}, ${imageUrl(src, {
          w: (width ?? 0) * 1.5,
          h: (height ?? 0) * 1.5,
          fm: 'avif',
          q: avifQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })} 1.5x, ${imageUrl(src, {
          w: (width ?? 0) * 2,
          h: (height ?? 0) * 2,
          fm: 'avif',
          q: avifQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })} 2x`}
      />
      <source
        type="image/webp"
        srcset={`${imageUrl(src, {
          w: width,
          h: height,
          fm: 'webp',
          q: webpQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })}, ${imageUrl(src, {
          w: (width ?? 0) * 1.5,
          h: (height ?? 0) * 1.5,
          fm: 'webp',
          q: webpQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })} 1.5x, ${imageUrl(src, {
          w: (width ?? 0) * 2,
          h: (height ?? 0) * 2,
          fm: 'webp',
          q: webpQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })} 2x`}
      />
      <img
        {width}
        {height}
        {alt}
        class={$$props.class}
        src={`${imageUrl(src, {
          w: width,
          h: height,
          fm: 'jpg',
          q: jpgQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })}, ${imageUrl(src, {
          w: (width ?? 0) * 1.5,
          h: (height ?? 0) * 1.5,
          fm: 'jpg',
          q: jpgQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })} 1.5x, ${imageUrl(src, {
          w: (width ?? 0) * 2,
          h: (height ?? 0) * 2,
          fm: 'jpg',
          q: jpgQuality,
          f: focusStrategy,
          fit: fitStrategy,
        })} 2x`}
        data-contentful-entry-id={entityId}
        data-contentful-field-id={fieldId}
      />
    </picture>
  {:else}
    <img
      {width}
      {height}
      {alt}
      class={$$props.class}
      src={imageUrl(src, {
        w: width,
        h: height,
        fm: 'jpg',
        q: jpgQuality,
        f: focusStrategy,
        fit: fitStrategy,
      })}
      data-contentful-entry-id={entityId}
      data-contentful-field-id={fieldId}
    />
  {/if}
{/if}
